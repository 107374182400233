import Moment from 'moment'

Moment.locale('en')

export const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
})

export function convertToMoney(numb) {
  if (!numb) return
  return formatter.format(numb / 100)
}

export function toDate(date, format = 'MMM Do') {
  if (date && typeof date.toDate === 'function') {
    date = date.toDate()
  }
  return Moment(date).format(format)
}

export function getPictureID(picture) {
  return picture
    .split('.com/')
    .pop()
    .split('/')[0]
}

export function slugify(word) {
  return word
    .toLowerCase()
    .replace(' ', '-')
    .replace('& ', '')
}
