import React from 'react'

import styles from './RentalDisplay.module.scss'
import Button from 'components/shared/Button'
import { convertToMoney, formatter, toDate } from 'utils/conversions'

const LegalNote = () => (
  <small className={styles.legal}>
    By confirming you agree to our
    <a target="blank" href="/legal/terms">
      {' '}
      Terms or Service{' '}
    </a>
    and
    <a target="blank" href="/legal/privacy">
      {' '}
      Privacy Policy{' '}
    </a>
    and have read and understand our{' '}
    <a
      target="blank"
      href="https://help.borrowmellow.com/category/15-cancellation-policies"
    >
      {' '}
      Cancellation Policy
    </a>
    .
  </small>
)

export default function RentalDisplay(props) {
  const {
    rental,
    onSubmit,
    submitting,
    onConfirmation,
    borrowerConfirmation = true,
    disabled = false,
  } = props

  if (!rental) {
    return null
  }

  const {
    item,
    start_date,
    end_date,
    total_price_nofee,
    total_price,
    service_fee,
    price_d,
    num_days,
    lender,
    lender_name,
    renter,
    renter_name,
  } = rental

  return (
    <div className={styles.rentalDisplayContainer}>
      <div className={styles.image}>
        <img className={styles.img} src={item.pictures[0]} />
      </div>
      <form
        onSubmit={e => (e.preventDefault(), onSubmit())}
        className={styles.description}
      >
        <h3>{item.name}</h3>
        {borrowerConfirmation ? (
          <div>
            <span>From</span>
            <a
              onClick={e => {
                navigate(getRoute('PROFILE', { id: lender.id }))
                e.stopPropagation()
              }}
            >
              {lender_name}
            </a>
          </div>
        ) : (
          <div>
            <span>Borrower</span>
            <a
              onClick={e => {
                navigate(getRoute('PROFILE', { id: renter.id }))
                e.stopPropagation()
              }}
            >
              {renter_name}
            </a>
          </div>
        )}
        <div>
          <span>Start Date</span>
          <b>{toDate(start_date, 'dddd, MMMM Do')}</b>
        </div>
        <div>
          <span>End Date</span>
          <b>{toDate(end_date, 'dddd, MMMM Do')}</b>
        </div>
        <div>
          <span>Duration</span>
          <b>
            {num_days} Day{num_days > 1 ? 's' : ''}
          </b>
        </div>
        <div>
          <span>Daily Cost</span>
          <b>{convertToMoney(price_d)}</b>
        </div>
        <div>
          <span>Subtotal</span>
          <b>{formatter.format(total_price_nofee)}</b>
        </div>
        <div>
          <span>Service Fee</span>
          <b>{formatter.format(service_fee)}</b>
        </div>
        <div className={styles.totalRow}>
          <b>TOTAL</b>
          <b>{formatter.format(total_price)}</b>
        </div>

        <Button big disabled={disabled}>
          {submitting ? 'Please Wait' : 'Confirm Rental'}
        </Button>
        <LegalNote />
      </form>
    </div>
  )
}
