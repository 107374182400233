import React, { useState } from 'react'
import { navigate } from 'gatsby'
import moment from 'moment'
import { observer } from 'mobx-react-lite'

import styles from './rent-item.module.scss'
import Layout from 'layouts/AuthenticatedPage'
import SEO from 'components/shared/SEO'
import BillingInfoSelector from 'components/BillingInfoSelector/BillingInfoSelector'
import { DatabaseService } from 'services'
import { getRoute } from 'utils/index'
import { useNavbarConfig } from 'components/Navbar/NavbarConfig'
import useAsyncEffect from 'utils/hooks/useAsyncEffect'
import UserStateStore from 'state/UserStateStore'
import RentalDisplay from 'components/Displays/RentalDisplay'
import Button from 'components/shared/Button'

function RentItemPage(props) {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [billingSource, setBillingSource] = useState(null)
  const [error, setError] = useState(false)

  const rental = props.location.state.rental
  const onSubmit = async () => {
    setIsSubmitting(true)
    try {
      const result = await DatabaseService.createRental({
        source: billingSource,
        itemId: rental.item.id,
        start_date: rental.start_date,
        end_date: rental.end_date,
      })
      navigate('/rent-item/success')
    } catch (error) {
      setError(true)
      setIsSubmitting(false)
    }
  }
  return (
    <div
      className={[styles.container, isSubmitting ? styles.submitting : ''].join(
        ' '
      )}
    >
      <div style={{ flex: 1 }}>
        <div className={styles.billingInfo}>
          <h3>Select Payment Method</h3>
          <BillingInfoSelector
            submitting={isSubmitting}
            onChangeSelection={source => setBillingSource(source)}
          />
          <div className={styles.noteContainer}>
            <small>
              NOTE: You will not be charged for the rental until it is accepted
              by the lender.
            </small>
          </div>
        </div>
      </div>
      <div className={styles.rentalDisplay}>
        <div className={styles.rentalDisplaySticky}>
          <RentalDisplay
            type="lend"
            onSubmit={onSubmit}
            submitting={isSubmitting}
            disabled={billingSource === null}
            rental={rental}
          />
        </div>
      </div>
      <div className={styles.mobileConfirmContainer}>
        <Button big onClick={onSubmit} disabled={billingSource === null}>
          Confirm Rental
        </Button>
      </div>
    </div>
  )
}

export default observer(({ location }) => {
  // when the page loads, asynchronously ping the backend
  // so that cold starts don't take as long

  // when the page loads, asynchronously ping the backend
  // so that cold starts don't take as long
  useAsyncEffect(async () => {
    if (!UserStateStore.user) {
      return
    }
    await DatabaseService.ping()
  }, [UserStateStore.user])
  useNavbarConfig({ mode: 'stuck' })
  return (
    <Layout location={location} showBeacon={true}>
      <SEO title="Rental" />
      <RentItemPage location={location} />
    </Layout>
  )
})
