/**
 * Some Button Form Elements
 * If no onClick handler is provided then the button
 * will not be rendered. This is useful shorthand for
 * selectively displaying buttons on certain forms but
 * not others. This could also be used to show a disabled
 * version of the button. Sky's the limit.
 */
import React from 'react'
import Button from 'components/shared/Button'

export const BackButton = props => {
  if (!props.onClick) {
    return <div />
  }
  return (
    <div>
      <Button inverse {...props}>
        {props.backText || 'Back'}
      </Button>
    </div>
  )
}

export const ForwardButton = props => {
  if (!props.onClick) {
    return <div />
  }

  return (
    <div>
      <Button {...props} disabled={!props.nextReady}>
        {props.nextText || 'Next'}
      </Button>
    </div>
  )
}

export const SubmitButton = props => {
  if (!props.onClick) {
    return <div />
  }
  return (
    <div>
      <Button {...props}>{props.submitText || 'Submit'}</Button>
    </div>
  )
}

export const FormButtonsElement = props => {
  return (
    <div style={{ display: `flex`, justifyContent: `space-between` }}>
      <BackButton onClick={props.back} backText={props.backText} />
      {props.forward ? (
        <ForwardButton
          onClick={props.forward}
          nextReady={props.nextReady}
          nextText={props.nextText}
        />
      ) : (
        <SubmitButton
          onClick={props.submit}
          disabled={props.disabled}
          submitText={props.submitText}
        />
      )}
    </div>
  )
}

export default FormButtonsElement
