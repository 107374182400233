import React from 'react'

import styles from './FormInput.module.scss'

export default ({ children, label, ...props }) => (
  <div className={styles.fieldsetWrapper}>
    <fieldset
      {...props}
      className={[styles.fieldset, label ? styles.fieldsetWithLabel : ''].join(
        ' '
      )}
    >
      {label && <label>{label}</label>}
      {children}
    </fieldset>
  </div>
)
